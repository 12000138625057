import React, { useEffect, useState, useContext } from "react"
import logo from "../images/logo_nemezink_white.svg"
import { FaInstagram, FaPhoneAlt, FaFacebookSquare, FaPinterestSquare, FaPhoneSquareAlt } from "react-icons/fa"
import tw, { styled } from "twin.macro"
import { useViewportScroll, motion } from "framer-motion"
import MobileHeader from "./MobileHeader"
import { Link, useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';

const wrapperVariants = {
  primary: {
    backgroundColor: "",
    height: "7vw",
    transition: { duration: 0.4, ease: "easeInOut" },
  },
  secondary: {
    backgroundColor: "#000000",
    height: "6vw",
    transition: { duration: 0.4, ease: "easeInOut" },
  },
}

const logoVariants = {
  primary: {
    width: "12vw",
    marginRight: "0vw",
    transition: { duration: 0.4, ease: "easeInOut" },
  },
  secondary: {
    width: "9vw",
    marginRight: "3vw",
    transition: { duration: 0.4, ease: "easeInOut" },
  },
}

const Header = ({ location }) => {
  const { scrollY } = useViewportScroll()
  const { languages, changeLanguage } = useI18next();

  const context = useContext(I18nextContext);

  const [headerVariant, setHeaderVariant] = useState("secondary");

  const [expandedArtists, setExpandedArtists] = useState(false);

  const [] = useState(false);

  useEffect(() => {
    function updateHeader() {
      if (location !== "/" && location !== "/en/") {
        setHeaderVariant("secondary")
      } else {
        if (scrollY.current < 50) {
          setHeaderVariant("primary")
        } else {
          setHeaderVariant("secondary")
        }
      }
    }

    const unsubY = scrollY.onChange(updateHeader)
    updateHeader()
    return () => unsubY()
  }, [location, scrollY])
  return (
    <Wrapper>
      <MobileHeader />
      <DesktopHeader
        variants={wrapperVariants}
        initial="secondary"
        animate={headerVariant}
      >
        <Link to="/">
          <Logo
            src={logo}
            variants={logoVariants}
            initial="secondary"
            animate={headerVariant}
            alt="nemezink logo"
          />
        </Link>
        <Menu>
          <MenuItem
            to="/"
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname === "/" || window.location.pathname === "/en/")
            }
          >
            START
          </MenuItem>
          <MenuItem
            to="/studio"
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/studio") ||
                window.location.pathname.startsWith("/en/studio"))
            }
          >
            STUDIO
          </MenuItem>
          {/* <MenuItem
            onClick={(e) => e.preventDefault()}
            to="/tattoo"
            onMouseEnter={() => setExpandedArtists(true)}
            onMouseLeave={() => setExpandedArtists(false)}
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/portfolio") ||
                window.location.pathname.startsWith("/en/portfolio"))
            }
          >
            {context.language === 'pl' ? 'NASI ARTYŚCI' : 'OUR ARTISTS'}
            <ArtistsCategories
            onMouseEnter={() => setExpandedArtists(true)}
            onMouseLeave={() => setExpandedArtists(false)}
            expandedArtists={expandedArtists} />
          </MenuItem> */}
          <MenuItem
            to="/tattoo"
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/tattoo") ||
                window.location.pathname.startsWith("/en/tattoo"))
            }
          >
            {context.language === 'pl' ? 'NASI ARTYŚCI' : 'OUR ARTISTS'}
          </MenuItem>
          <MenuItem
            to="/faq"
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/faq") ||
                window.location.pathname.startsWith("/en/faq"))
            }
          >
            FAQ
          </MenuItem>
          <MenuItem
            to="https://blog.nemezink.pl/"
            language="pl"
          >
            BLOG
          </MenuItem>
          <MenuItem
            to="/contact"
            isSelected={
              typeof window !== "undefined" &&
              (window.location.pathname.startsWith("/contact") ||
              window.location.pathname.startsWith("/en/contact"))
            }
          >
            {context.language === 'pl' ? 'KONTAKT' : 'CONTACT'}
          </MenuItem>
        </Menu>
        <IconContainer>
          <a
          href="tel:+48690971677"
          >
            <PhoneIcon />
          </a>
          <a
          rel="nofollow"
            href="https://www.instagram.com/nemezink/"
            alt="nemezink instagram"
          >
            <IgIcon />
          </a>
          <a
          rel="nofollow"
            href="https://www.facebook.com/Nemezink-Tattoo-Studio-102564294946277"
            alt="nemezink facebook"
          >
            <FbIcon />
          </a>
          <a
          rel="nofollow"
            href="https://www.pinterest.com/nemezink"
            alt="nemezink pinterest"
          >
            <PinterestIcon />
          </a>
          <div tw="flex flex-row items-center justify-center h-full cursor-pointer">
            <LangButton
              active={context.language === "pl"}
              onClick={(e) => {
                e.preventDefault();
                changeLanguage('pl');
              }}
            >PL</LangButton>
            <div
              style={{
                width: "1px",
                backgroundColor: "#fbfbfb",
                height: "1vw",
                margin: "0vw 0.5vw"
              }}
            />
            <LangButton
              active={context.language === "en"}
              onClick={(e) => {
                e.preventDefault();
                changeLanguage('en');
              }}
            >EN</LangButton>
          </div>
        </IconContainer>
      </DesktopHeader>
    </Wrapper>
  )
}

const ArtistsCategories = ({expandedArtists}) => {
  return <Categories expandedArtists={expandedArtists}>
    <MenuItem
      to="/tattoo"
      isSelected={
        typeof window !== "undefined" &&
        (window.location.pathname.startsWith("/tattoo") ||
          window.location.pathname.startsWith("/en/tattoo"))
      }
    >Tattoo</MenuItem>
    <MenuItem
      to="/piercing"
      isSelected={
        typeof window !== "undefined" &&
        (window.location.pathname.startsWith("/piercing") ||
          window.location.pathname.startsWith("/en/piercing"))
      }
    >Piercing</MenuItem>
  </Categories>
};


const Categories  = styled.div`
  ${tw `flex flex-row float-left p-0 m-0 h-full justify-center items-center justify-self-start`}
  height: 3vw;
  position: absolute;
  display: ${({ expandedArtists }) => (expandedArtists ? "flex" : "none")};
  top: ${({ expandedArtists }) => (expandedArtists ? "4vw" : "0vw")};
  opacity: ${({ expandedArtists }) => (expandedArtists ? "1" : "0")};
  left: 50%;
  transform: translate(-50%, -100%);
  transition: all 5s linear;
`

const LangButton = styled.a`
    font-weight: ${({ active }) => (active ? "bold" : "normal")};
    font-size: ${({ active }) => (active ? "1vw" : "0.9vw")};
    color: ${({ active }) => (active ? "#fbfbfb" : "#e7e7e7")};
`

const Wrapper = styled.div`
  ${tw`w-full float-left m-0 fixed top-0 left-0 z-30 box-border`}
`

const DesktopHeader = styled(motion.div)`
  ${tw` flex flex-row flex-wrap items-center  content-center transition ease-in-out duration-1000 box-border float-left w-full m-0   justify-between`}
  padding: 0px 3vw 0px 3vw;
  color: #fbfbfb;
  @media (max-width: 1024px) {
    display: none;
  }
`

const Menu = styled.div`
  ${tw`flex flex-row float-left relative p-0 m-0 h-full justify-center items-center justify-self-start`}
  color: #fbfbfb;
  margin: auto;
`
const MenuItem = styled(Link)`
  ${tw`m-0 flex flex-row items-center relative uppercase transition ease-in-out duration-700  cursor-pointer`}
  font-weight: bold;
  margin: 0px 1.2vw 0px 1.2vw;
  font-size: 1.05vw;
  letter-spacing: 0.05em;
  outline: 0;
  :after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    display: block;
    height: 2px;
    left: ${({ isSelected }) => (isSelected ? 0 : "50%")};
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: ${({ isSelected }) => (isSelected ? "100%" : 0)};
    content: "";
  }
  :hover:after {
    width: 100%;
    left: 0;
  }
`

const Logo = styled(motion.img)`
  ${tw`float-left p-0 relative transition ease-in-out duration-300`}
  margin-bottom:0px;
`

const IconContainer = styled.div`
  ${tw`flex flex-row float-left h-full items-center justify-center justify-self-end`}
  width: 12vw;
`

const IgIcon = styled(FaInstagram)`
  ${tw`cursor-pointer duration-300 transform hover:scale-110`}
  font-size: 1.7vw;
  color: #fbfbfb;
  margin: 0px 1vw 0px 0px;
`

const FbIcon = styled(FaFacebookSquare)`
  ${tw`cursor-pointer duration-300 transform hover:scale-110`}
  font-size: 1.7vw;
  color: #fbfbfb;
  margin: 0px 1vw 0px 0px;
`

const PinterestIcon = styled(FaPinterestSquare)`
  ${tw`cursor-pointer duration-300 transform hover:scale-110`}
  font-size: 1.7vw;
  color: #fbfbfb;
  margin: 0px 1vw 0px 0px;
`

const PhoneIcon = styled(FaPhoneAlt)`
  ${tw`cursor-pointer duration-300 transform hover:scale-110`}
  font-size: 1.3vw;
  color: #fbfbfb;
  margin: 0px 1vw 0px 0px;
`

export default Header
